import {
    CART_TYPE,
    CART_TYPE_1_COUNT,
    CART_TYPE_3_COUNT,
    CART_TYPE_4_COUNT,
    ELIGIBLE_PARENTS,
    OVLABEL,
    RESOURCE_TYPE_DISPLAY_FIELDS,
    RESOURCE_TYPES,
    VISIBLE_ATTRIBUTES,
    CAPABILITY_CONFIG,
    STRINGS,
    EXCEL_TYPES,
    VALID_SHEET_NAMES,
    RESOURCE_ASSOCIATION_DISTANCE_TYPES,
    ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS,
    ENDPOINT_GET_RESOURCES_RELATIONSHIPS,
    ENDPOINT_GET_RESOURCES_V2,
    FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING,
    RELATIONSHIP_TYPES,
    FRONTEND_TO_BACKEND_RESOURCEATTRIBUTES_MAPPING,
    NODE_RESOURCES_TABS,
    ENDPOINT_BULK_CREATE_RESOURCES,
    TYPE,
    ALL_ATTRIBUTES,
    VALID_HEADER_ROWS,
    DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES,
    LAUNCHPAD_STAGING_ZONE_MAP,
    DEPRECATE_HIERARCHY_RESOURCE_ASSOCIATION_TYPES,
    REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL,
    DEFAULT_FORMATTED_MESSAGES,
    ROW_LIMIT_FOR_EACH_SHEET,
    VALID_STATUS_TYPES,
    INACTIVE_STATUS_TYPE,
    REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL_GENERIC,
    OVD_FIX_ENABLED_SUFFIX,
    ACTIVE_STATUS_TYPE,
    SAVE_OPERATION_TYPES,
    REVIEW_STATUS_TYPES,
    GET_RESOURCES_V2_FILTER_REQUEST_ATTRIBUTE,
    REDUNDANT_HEADER_ROWS,
    CLUSTER_TYPES,
    RESOURCE_ASSOCIATION_TO_CHILD_RESOURCE_TYPE_MAP,
    SIX_BIN_CART_BINS_COUNT,
    SIX_BIN_CART_SUFFIX_LABEL,
    SIX_BIN_CART_TIERS,
    AMBIENT_TEMPERATURE_RATING, CHILLED_TEMPERATURE_RATING, FROZEN_TEMPERATURE_RATING,
    VALID_TEMPERATURE_RATING, TEMPERATURE_FILTER_TYPE, SSD, SSD_V2, GET_RESOURCES_V2_RESOURCE_TYPE_REQUEST_ATTRIBUTE
} from "../Constants";
import readXlsxFile from "read-excel-file";
import { FormattedMessage } from "react-intl";
import React from 'react';

export const ERROR_MAP = {
    INVALID_FILE_INPUT: "Invalid File Input"
}

export function addBaseProperties(dataMap, nodeResource, resourceType) {
    if (nodeResource.resourceType === RESOURCE_TYPES.STAGING_AREA){
        dataMap.Staging_Area_Label = nodeResource.label;
        dataMap.Staging_Area_Scannable = nodeResource.scannableId;
    } else if (nodeResource.resourceType === RESOURCE_TYPES.AISLE) {
        dataMap.Aisle_Label = nodeResource.label;
        dataMap.Aisle_Scannable = nodeResource.scannableId;
    } else if (resourceType === RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP && nodeResource.resourceType === RESOURCE_TYPES.CLUSTER) {
        dataMap.Cluster_Label = nodeResource.label;
        dataMap.Cluster_Scannable = nodeResource.scannableId;
    } else if ([RESOURCE_TYPES.AISLE_BIN_ASSOCIATION, RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION].includes(resourceType) && nodeResource.resourceType === RESOURCE_TYPES.BIN){
        dataMap.Bin_Label = nodeResource.label;
        dataMap.Bin_Scannable = nodeResource.scannableId;
    } else if (RESOURCE_TYPES.DOCK_DOOR_STAGING_AREA_MAP === resourceType && nodeResource.resourceType === RESOURCE_TYPES.DOCK_DOOR){
        dataMap.Staging_Area_Label = nodeResource.label;
        dataMap.Staging_Area_Scannable = nodeResource.scannableId;
    } else if (resourceType === RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION && nodeResource.resourceType === RESOURCE_TYPES.STAGING_ZONE){
        dataMap.Staging_Zone_Label = nodeResource.label;
        dataMap.Staging_Zone_Scannable = nodeResource.scannableId;
    } else {
        dataMap.label = nodeResource.label;
        dataMap.scannableId = nodeResource.scannableId;
    }
    dataMap.resourceId = nodeResource.resourceId;
    dataMap.resourceType = nodeResource.resourceType;
    dataMap.parentResourceId = nodeResource.parentResourceId;
    dataMap.isDeprecated = nodeResource.isDeprecated;
    return dataMap;
}

export const getResourceTypes = (narc_sls_intergration_enabled, capability, currentTab, isNARCSSDBinCreationEnabled = false) => {
    if(narc_sls_intergration_enabled){
        capability = currentTab === NODE_RESOURCES_TABS.RESOURCES_TAB ? (capability + "_RESOURCES") : (capability + "_Associations");
    }
    if (CAPABILITY_CONFIG.hasOwnProperty(capability)) {
        switch (capability) {
            case SSD:
                if(isNARCSSDBinCreationEnabled) {
                    return CAPABILITY_CONFIG[SSD_V2]["RESOURCE_TYPES"];
                }
                return CAPABILITY_CONFIG[SSD]["RESOURCE_TYPES"];
            default:
                return CAPABILITY_CONFIG[capability]["RESOURCE_TYPES"];
        }
    } else {
        return CAPABILITY_CONFIG.DEFAULT["RESOURCE_TYPES"];
    }
}

export function convertImmediateResourceData(nodeResources, resourceType) {
    let childResources = [];
    nodeResources.forEach(nodeResource => {
        if (nodeResource != null) {
            if (RESOURCE_ASSOCIATION_TO_CHILD_RESOURCE_TYPE_MAP.hasOwnProperty(resourceType)) {
                if (!RESOURCE_ASSOCIATION_TO_CHILD_RESOURCE_TYPE_MAP[resourceType].includes(nodeResource.resourceType)) {
                    return;
                }
            }
            let dataMap = {};

            // STEP : Put in additional parameters
            addBaseProperties(dataMap, nodeResource, resourceType);

                // STEP : Get the attribute map
                if (nodeResource.resourceAttributes != null && Object.keys(nodeResource.resourceAttributes).length !== 0) {
                    let attributes = nodeResource.resourceAttributes;
                    for (let key in attributes) {
                        if (attributes.hasOwnProperty(key) && attributes[key] !== null) {
                            dataMap[key] = attributes[key];
                        }
                    }
                }
                childResources.push(dataMap);
        }
    });

    return childResources;
}

/**
 * Fetches the rquired headers which are not present in excel file
 * @param sheetName
 * @param headerRows
 * @returns {*}
 */
const fetchRequiredHeadersNotPresentInFile = (headerRows, requiredHeaders) => {
    const requiredHeadersNotPresentinExcelFile = requiredHeaders.filter(
        headerRow => !headerRows.includes(headerRow));
    return requiredHeadersNotPresentinExcelFile;
}

/**
 * Prepare the sheet map
 * @param rows
 * @returns {null|[]}
 */
export function getSheetMap(rows, sheetName, narc_sls_intergration_enabled) {
    let sheetMapList = [];
    let uploadBulkResourceError = null;
    if (rows != null && rows.length > 1) { // We need to have atleast 2 rows.
        let header = rows[0];
        let data = rows.slice(1);
        if (narc_sls_intergration_enabled && data.length > ROW_LIMIT_FOR_EACH_SHEET) {
            return {
                uploadBulkResourceError : `Please limit the data present in each sheet to ${ROW_LIMIT_FOR_EACH_SHEET} rows, ${sheetName} contains ${data.length} rows`
            }
        }
        let uniqueHeaderList = new Set();
        data.forEach((dataRow, dataIndex) => {
            if (uploadBulkResourceError) {
                return;
            }
            let sheetMap = {};
            
            header.forEach((headerName, headerIndex) => {

                if(REDUNDANT_HEADER_ROWS.hasOwnProperty(sheetName) && REDUNDANT_HEADER_ROWS[sheetName].includes(headerName)) {
                    return;
                }

                let cellValue = dataRow[headerIndex];
                if(cellValue !== null && cellValue !== STRINGS.EMPTY) {
                    if(sheetMap[headerName] === null
                        || sheetMap[headerName] === STRINGS.EMPTY
                        || typeof sheetMap[headerName] === "undefined") {
                        sheetMap[headerName] = cellValue.toString().trim();
                        uniqueHeaderList.add(headerName);
                    } else {
                        sheetMap[headerName] += "," + cellValue.toString().trim();
                    }
                }
            });  
 
            rows[0] = Array.from(uniqueHeaderList);
            // Checks if all required columns are not present for current row
            if (Object.keys(REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL).includes(sheetName)) {
                /* currentRowIndex defines the current excel row
                   currentRowIndex = parseInt(dataIndex) + 2, where dataIndex defines excel row iterator with index
                   starting from 0 and iteration starts from excel data rows (Not including Headers). Now, in actual excel file,
                   row indices would start from 1, and includes header, thus, adding +2 here to help user identify the actual excel row
                   which is missing required column.
                 */
                const currentRowIndex = parseInt(dataIndex) + 2;
                /**
                 * Validates if status attribute has any other value than [Active, Inactive]
                 */
                const isStatusParameterPresent = Object.keys(sheetMap).includes(ALL_ATTRIBUTES.Status);
                let requiredHeaders = REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL[sheetName];
                if (isStatusParameterPresent && !VALID_STATUS_TYPES.includes(sheetMap[ALL_ATTRIBUTES.Status].toUpperCase())) {
                    uploadBulkResourceError = `Invalid value ${sheetMap[ALL_ATTRIBUTES.Status]} of Status for row index : ${currentRowIndex} in sheet : ${sheetName}, Allowed Values of Status are [${VALID_STATUS_TYPES}]`;
                    return;
                }

                /**
                 * Validates if temperature attribute has any other value than [AMBIENT, CHILLED, FROZEN]
                 */
                const isTemperatureParameterPresent = Object.keys(sheetMap).includes(ALL_ATTRIBUTES.Temperature);
                if (isTemperatureParameterPresent && !VALID_TEMPERATURE_RATING.includes(sheetMap[ALL_ATTRIBUTES.Temperature].toUpperCase())) {
                    uploadBulkResourceError = `Invalid value ${sheetMap[ALL_ATTRIBUTES.Temperature]} of Temperature for row index : ${currentRowIndex} in sheet : ${sheetName}, Allowed Values of Temperature are [${VALID_TEMPERATURE_RATING}]`;
                    return;
                }

                if (isStatusParameterPresent && sheetMap[ALL_ATTRIBUTES.Status].toUpperCase() === INACTIVE_STATUS_TYPE) {
                    requiredHeaders = REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL_GENERIC;
                }
                const requiredHeadersNotPresentInExcelFile = fetchRequiredHeadersNotPresentInFile(Object.keys(sheetMap), requiredHeaders);
                if (requiredHeadersNotPresentInExcelFile && requiredHeadersNotPresentInExcelFile.length > 0) {
                     uploadBulkResourceError = `Required Column(s) ${requiredHeadersNotPresentInExcelFile.toString()} are missing for row index : ${currentRowIndex} in sheet : ${sheetName}`;
                     return;
                }
            }
            if (Object.keys(sheetMap).length > 0 ) {
                sheetMapList.push(sheetMap);
            }
        });
    }

    if (uploadBulkResourceError) {
        return {
            uploadBulkResourceError : uploadBulkResourceError
        }
    } else if(sheetMapList.length > 0) {
        return {
            sheetMapList : sheetMapList
        };
    } else {
        return null;
    }
}

function setParentResourceLabels(resource) {
    for(let index = 0; index < ELIGIBLE_PARENTS.length; index++) {
        if(resource.hasOwnProperty(ELIGIBLE_PARENTS[index])) {
            return resource[ELIGIBLE_PARENTS[index]].split(",");
        }
    }
}

function setBasicProperties(resource) {
    return {
        nodeId: resource.nodeId,
        resourceType: resource.resourceType,
        resourceAttributes: resource.resourceAttributes,
        label: resource.label,
        isDeprecated: resource.isDeprecated,
        scannableId: resource.scannableId,
        resourceId: resource.resourceId,
    }
}

export function isSheetNameValid(sheetName, resourceTypes, type_excel='', narc_sls_intergration_enabled=false, isSSDMMOTEnabled = false) {
    //Verify sheet names for SLS resources and Associations excel sheets if flag is set
    if (narc_sls_intergration_enabled || isSSDMMOTEnabled){
        if(type_excel === EXCEL_TYPES.RESOURCES_EXCEL || type_excel === EXCEL_TYPES.RESOURCES_ASSOCIATIONS_EXCEL){
            return VALID_SHEET_NAMES[type_excel].includes(sheetName);
        }
    }
    if (resourceTypes && resourceTypes.length > 0) {
        return resourceTypes.includes(sheetName);
    }
    return false;
}

function getResource(nodeId, resourceLabel, resourceType, resourceAttributeType) {
    return {
        resourceType: resourceType,
        label: resourceLabel,
        nodeId: nodeId,
        resourceAttributes: {
            Type: resourceAttributeType
        }
    };
}

export function getNormalChildren(nodeId, units, childType, childAttributeType) {

    /**
     * When 4 units, labels are A1,A2,B1,B2
     * When 6 units, labels are A1,A2,B1,B2,C1,C2
     *
     * That is, the rule being setup is, bins/VLs would be X1 and X2, where X is any character.
     */
    const groupSize = 2;
    const groupCount = Math.floor(units / groupSize);
    const excessCount = Math.abs((groupCount * groupSize) - units);
    let children = [];
    let labelPrefix = 'A';

    if (groupCount > 0) {
        let prefix = labelPrefix;

        // generating count range for prefix
        let groupCountRange = [...Array(groupCount).keys()];
        let groupCountRangeSuffix = Array.from({length:groupSize},(v,k)=>k+1);

        groupCountRange.forEach(prefixLabel => {
            groupCountRangeSuffix.forEach(suffixLabel => {
                let prepareLabel =  String.fromCharCode(prefix.charCodeAt(0) + prefixLabel) + suffixLabel;
                children.push(getResource(nodeId, prepareLabel,
                    childType,
                    childAttributeType));
            })
        });
    }

    // Add any excess count found
    if (excessCount > 0) {

        let prefix = String.fromCharCode(labelPrefix.charCodeAt(0) + groupCount);
        let groupCountRange = [...Array(groupCount).keys()];
        groupCountRange.forEach(index => {
            children.push(getResource(nodeId, String.fromCharCode(prefix.charCodeAt(0) + index) + (index + 1),
                childType,
                childAttributeType));
        });
    }

    return children;
}


export function getNormalChildrenForPickCart(nodeId, units, childType, childAttributeType, suffixLabel, tiers) {
    /**
     *
     * When 6 units, labels are 1X,2X,3X,1Y,2Y,3Y
     * SuffixLabel represents the Initial Character for columns of the cart
     */
    const tierCount = tiers;
    const columnCount = Math.floor(units / tierCount);
    const excessChildCount = Math.abs((columnCount * tierCount) - units);
    let children = [];

    if (columnCount > 0) {
        let startingCharForColumns = suffixLabel;

        let columnRange = [...Array(columnCount).keys()]; //[0,1]
        let tierRange = Array.from({length:tierCount},(v,k)=>k+1); //[1,2,3]

        columnRange.forEach(columnIndex => {
            tierRange.forEach(tierLabel => {
                let prepareLabel = '' + tierLabel + String.fromCharCode(startingCharForColumns.charCodeAt(0) + columnIndex) ;
                children.push(getResource(nodeId, prepareLabel,
                    childType,
                    childAttributeType));
            })
        });
    }

    // Add any excess count found
    if (excessChildCount > 0) {

        let startingCharForExcessChildren = String.fromCharCode(suffixLabel.charCodeAt(0) + columnCount);
        let columnRange = [...Array(columnCount).keys()];
        columnRange.forEach(index => {
            children.push(getResource(nodeId, '' + (index + 1) + String.fromCharCode(startingCharForExcessChildren.charCodeAt(0) + index),
                childType,
                childAttributeType));
        });
    }

    return children;
}

function getChildren(nodeResourceV2, childType, normalChildAttributeType, ovChildAttributeType) {
    const nodeId = nodeResourceV2.nodeId;
    let childrenList = [];
    let cartType = STRINGS.EMPTY;

    if (Object.keys(nodeResourceV2).length !== 0 && nodeResourceV2.resourceAttributes.Type) {
        cartType = nodeResourceV2.resourceAttributes.Type;
    }

    // Make changes by CART_TYPE.
    if (CART_TYPE.CART_TYPE1 === cartType) {
        childrenList = getNormalChildren(nodeId, CART_TYPE_1_COUNT, childType, normalChildAttributeType);

        // Set children count
        nodeResourceV2.resourceAttributes.NormalUnits = childrenList.length;
        nodeResourceV2.resourceAttributes.OvUnits = 0;

    } else if (CART_TYPE.CART_TYPE2 === cartType) {
        childrenList.push(getResource(nodeId, OVLABEL, childType, ovChildAttributeType));

        // Set children count
        nodeResourceV2.resourceAttributes.NormalUnits = 0;
        nodeResourceV2.resourceAttributes.OvUnits = 1;

    } else if (CART_TYPE.CART_TYPE3 === cartType) {
        childrenList = getNormalChildren(nodeId, CART_TYPE_3_COUNT, childType, normalChildAttributeType);

        nodeResourceV2.resourceAttributes.NormalUnits = childrenList.length;
        childrenList.push(getResource(nodeId, OVLABEL, childType, ovChildAttributeType));

        // Set children count
        nodeResourceV2.resourceAttributes.OvUnits = 1;
    } else if (CART_TYPE.CART_TYPE4 === cartType) {
        childrenList = getNormalChildren(nodeId, CART_TYPE_4_COUNT, childType, normalChildAttributeType);

        nodeResourceV2.resourceAttributes.NormalUnits = childrenList.length;
        childrenList.push(getResource(nodeId, OVLABEL, childType, ovChildAttributeType));

        // Set children count
        nodeResourceV2.resourceAttributes.OvUnits = 1;
    } else if (CART_TYPE.SIX_BIN_CART === cartType) {
        childrenList = getNormalChildrenForPickCart(nodeId, SIX_BIN_CART_BINS_COUNT, childType,
            normalChildAttributeType, SIX_BIN_CART_SUFFIX_LABEL, SIX_BIN_CART_TIERS);

        // Set children count
        nodeResourceV2.resourceAttributes.NormalUnits = childrenList.length;
        nodeResourceV2.resourceAttributes.OvUnits = 0;
    }

    return childrenList;
}

function getCartChildren(nodeResourceV2) {
    return getChildren(nodeResourceV2, RESOURCE_TYPES.BIN, RESOURCE_TYPES.BIN, RESOURCE_TYPES.OV_BIN);
}

function getCartLocationChildren(nodeResourceV2) {
    return getChildren(nodeResourceV2, RESOURCE_TYPES.VIRTUAL_LOCATION, RESOURCE_TYPES.VL, RESOURCE_TYPES.OV_VL);
}

function setChildResources(nodeResourceV2, resourceType) {
    switch(resourceType) {
        case RESOURCE_TYPES.CART:
            return getCartChildren(nodeResourceV2);
        case RESOURCE_TYPES.CART_LOCATION:
            return getCartLocationChildren(nodeResourceV2);
        default:
            // do nothing
            break;
    }
}

function getVisibleAttributesColumnList(resourceType, isOVDFeatureEnabled) {
    if (isOVDFeatureEnabled && VISIBLE_ATTRIBUTES.hasOwnProperty(resourceType + OVD_FIX_ENABLED_SUFFIX)) {
        return VISIBLE_ATTRIBUTES[resourceType + OVD_FIX_ENABLED_SUFFIX];
    }
    return VISIBLE_ATTRIBUTES.hasOwnProperty(resourceType) ? VISIBLE_ATTRIBUTES[resourceType] : [];
}

/**
 * Transform backend attributes to display attributes for UI
 */
export const convertBackendAttributesToFrontendAttributes = (responseData, resourceSearchCriteria, isOVDFeatureEnabled) => {
    let resourceType = resourceSearchCriteria.resourceType;
    if (isOVDFeatureEnabled && FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING.hasOwnProperty(resourceSearchCriteria.resourceType + OVD_FIX_ENABLED_SUFFIX)) {
        resourceType = resourceSearchCriteria.resourceType + OVD_FIX_ENABLED_SUFFIX;
    }
    if(!(resourceType in FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING)){
        return responseData;
    }
    let transformedResponseData;
    if([RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE,
        RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE].includes(resourceType)){
            transformedResponseData = {nodeResources: responseData};
    } else {
        transformedResponseData = responseData;
    }
    const frontendToBackendAttributeMapping = FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING[resourceType];
    const reverseAttributeMapping = reverseMapping(frontendToBackendAttributeMapping);
    transformedResponseData.nodeResources = (transformedResponseData.nodeResources).map(resource => {
        let transformedResource = {};
        transformedResource.resourceType = resourceSearchCriteria.resourceType;
        transformedResource.resourceAttributes = {}
        Object.keys(reverseAttributeMapping).forEach(attribute => {
            const displayAttribute = reverseAttributeMapping[attribute];
            if(attribute in resource){
                transformedResource[displayAttribute] = resource[attribute];
            }
            if(resource.resourceAttributes && attribute in resource.resourceAttributes){
                (transformedResource.resourceAttributes)[displayAttribute] = (resource.resourceAttributes)[attribute];
            }
            if(resource.relationshipAttribute && attribute in resource.relationshipAttribute){
                (transformedResource.resourceAttributes)[displayAttribute] = (resource.relationshipAttribute)[attribute];
            }
        })
        return transformedResource;
    });
    return transformedResponseData;
}

export function parseResponseData(responseData, resourceSearchCriteria, isOVDFeatureEnabled, pageToken=null) {
    const transformedResponseData = convertBackendAttributesToFrontendAttributes(responseData, resourceSearchCriteria, isOVDFeatureEnabled);
    if(transformedResponseData.nodeResources) {
        transformedResponseData.nodeResources.forEach( nodeResource => {
            // STEP : Get the attribute map
            if (nodeResource.resourceAttributes !== undefined && Object.keys(nodeResource.resourceAttributes).length > 0) {
                const attributes = nodeResource.resourceAttributes;

                // STEP : Fetch the configuration parameters
                const visibleAttributes = getVisibleAttributesColumnList(nodeResource.resourceType, isOVDFeatureEnabled);

                // STEP : Put into the final Map
                if (visibleAttributes.length > 0) {
                    for (const attribute of visibleAttributes) {
                        nodeResource[attribute] = attributes[attribute];
                    }
                }
            }
        })
        let displayFieldsData = RESOURCE_TYPE_DISPLAY_FIELDS[resourceSearchCriteria.resourceType]["resource"];
        if (isOVDFeatureEnabled && RESOURCE_TYPE_DISPLAY_FIELDS.hasOwnProperty(resourceSearchCriteria.resourceType + OVD_FIX_ENABLED_SUFFIX)) {
            displayFieldsData = RESOURCE_TYPE_DISPLAY_FIELDS[resourceSearchCriteria.resourceType + OVD_FIX_ENABLED_SUFFIX]["resource"];
        }
        return {
            resources: transformedResponseData.nodeResources,
            displayFields: displayFieldsData,
            pageToken: pageToken
        }
    } else {
        return {
            error: transformedResponseData.error,
            resources: []
        }
    }
}

/**
 * Get the Map of sheet Name versus sheet data
 */
async function getSheetRowsMap(sheetIndexMapList, file) {
    let sheetRowsMap = {};
    for(let index=0; index<sheetIndexMapList.length; index++) {
        const sheet = sheetIndexMapList[index];
        const sheetName = sheet.name;
        const sheetRows = await readXlsxFile(file, { sheet: sheetName });
        sheetRowsMap[sheetName] = sheetRows;
    }
    return sheetRowsMap;
}

/**
 * fetch the Resource Data Map
 */
async function getResourceDataMap(stationCode, resourceTypes, event, type_excel='', narc_sls_intergration_enabled=false,
                                  isSSDMMOTEnabled = false) {
    const file = event.target.files[0];
    const sheetIndexMapList = await readXlsxFile(file, { getSheets : true} );
    if(sheetIndexMapList === undefined) {
        return {};
    }
    const sheetRowsMap = await getSheetRowsMap(sheetIndexMapList, file);
    return getResourceDataMapHelper(sheetRowsMap, resourceTypes, type_excel, narc_sls_intergration_enabled, isSSDMMOTEnabled);
}

export function getResourceDataMapHelper(sheetRowsMap, resourceTypes, type_excel, narc_sls_intergration_enabled, isSSDMMOTEnabled) {
    let uploadBulkResourceError = null;
    let resourceDataMap = {};
    //Consider all the sheets
    Object.keys(sheetRowsMap).forEach((sheetName) => {
        if (uploadBulkResourceError) {
            return;
        }
        if(isSheetNameValid(sheetName, resourceTypes, type_excel, narc_sls_intergration_enabled, isSSDMMOTEnabled)) {
            let sheetRows = sheetRowsMap[sheetName];
            if (sheetRows != null && sheetRows.length > 1) { // we need atleast two rows
   
                let validationErrorMap = validateHeaderRows(sheetRows[0], sheetName);
                if (validationErrorMap) {
                    uploadBulkResourceError = validationErrorMap;
                    return;
                }
                let sheetMapList = getSheetMap(sheetRows, sheetName, narc_sls_intergration_enabled);
                if (sheetMapList && Object.keys(sheetMapList).includes("uploadBulkResourceError")) {
                    uploadBulkResourceError = sheetMapList.uploadBulkResourceError;
                    return;
                } else {
                    sheetMapList = sheetMapList.sheetMapList;
                }
                // STEP : Create a map of the various sheets (corresponding to each resource type)
                resourceDataMap[sheetName] = {
                    headerRows: sheetRows[0],
                    sheetMapList: sheetMapList
                };
            }
        }
    });
    if (uploadBulkResourceError) {
        return {
            uploadBulkResourceError: uploadBulkResourceError,
        };
    }
    return resourceDataMap;
}

/**
 * Action for uploading the Resource Creation file
 */
export async function readResourceFile(stationCode, resourceTypes, event, type_excel='', narc_sls_intergration_enabled=false,
                                       isSSDMMOTEnabled = false) {
    const resourceDataMap = await getResourceDataMap(stationCode, resourceTypes, event, type_excel, narc_sls_intergration_enabled, isSSDMMOTEnabled);
    if(Object.keys(resourceDataMap).length === 0) {
        return {
            resourceDataMap: {},
            error: true,
            uploadBulkResourceError: ERROR_MAP.INVALID_FILE_INPUT
        }
    } else if (Object.keys(resourceDataMap).includes("uploadBulkResourceError")) {
        return {
            resourceDataMap: {},
            error: true,
            uploadBulkResourceError: resourceDataMap.uploadBulkResourceError
        }
    } 
    return {
                resourceDataMap: resourceDataMap
            }
}

export function getResourceCreationDataWithNodeResources(preparedJson, capability, endpoint, isOVDFeatureEnabled, isNARCSSDBinCreationEnabled) {
    const resourceByTypes = preparedJson.resources;
    const nodeId = preparedJson.nodeId;
    const nodeResourceDetailsList = [];

    resourceByTypes.forEach(resources => {
        const resourceList = resources.list;
        const resourceType = resources.type;

        resourceList.forEach( resource => {
            let nodeResourceDetails = {};
            if((VALID_SHEET_NAMES.RESOURCE_ASSOCIATIONS_EXCEL).includes(resourceType)){
                nodeResourceDetails = parseRequestObjectForUploadResources(resource, nodeId, resourceType);
            } else{
                resource.nodeId = nodeId;
                resource.resourceType = resourceType;
                let resourceAttributes = {};
                if(resourceType in FRONTEND_TO_BACKEND_RESOURCEATTRIBUTES_MAPPING){
                    const attributeMapping = FRONTEND_TO_BACKEND_RESOURCEATTRIBUTES_MAPPING[resourceType];
                    for (const key of Object.keys(resource)) {
                        if(key in attributeMapping){
                            let backendAttribute = attributeMapping[key];
                            resourceAttributes[backendAttribute] = resource[key]
                        }
                    }
                } else{
                    resourceAttributes = {"Type": resource.Type};
                }

                if(isNARCSSDBinCreationEnabled) {
                    if(resource.resourceType === RESOURCE_TYPES.BIN && (resource.Status).toUpperCase() === ACTIVE_STATUS_TYPE) {
                        resourceAttributes[FRONTEND_TO_BACKEND_RESOURCEATTRIBUTES_MAPPING.BIN.reviewStatus] = REVIEW_STATUS_TYPES.REVIEWED;
                    }
                }
                /**
                 * In case OVD Feature flag is enabled, 
                 * we will utilize "AddBulkResources" API to perform CREATE/UPDATE/DEPRECATE/REVIVE
                 * Setting "SaveOperation" Attributes in case resource needs to be deprecated/revived/updated
                 */
                if(isOVDFeatureEnabled) {
                    if((resource.Status).toUpperCase() === INACTIVE_STATUS_TYPE) {
                        resource.isDeprecated = true;
                        nodeResourceDetails.saveOperation = SAVE_OPERATION_TYPES.UPDATE;
                    } else if(resource.resourceType === RESOURCE_TYPES.BIN && (resource.Status).toUpperCase() === ACTIVE_STATUS_TYPE){
                        resource.isDeprecated = false;
                        resourceAttributes[FRONTEND_TO_BACKEND_RESOURCEATTRIBUTES_MAPPING.BIN.reviewStatus] = REVIEW_STATUS_TYPES.REVIEWED;
                        nodeResourceDetails.saveOperation = SAVE_OPERATION_TYPES.UPDATE;
                    } else if(resource.resourceType === RESOURCE_TYPES.AISLE && (resource.Status).toUpperCase() === ACTIVE_STATUS_TYPE) {
                        resource.isDeprecated = false;
                        nodeResourceDetails.saveOperation = SAVE_OPERATION_TYPES.UPDATE;
                    }
                }
                resource.resourceAttributes = resourceAttributes;
                nodeResourceDetails.resource = setBasicProperties(resource);
                nodeResourceDetails.childResourceList = setChildResources(nodeResourceDetails.resource, resourceType);
                nodeResourceDetails.parentResourceLabels = setParentResourceLabels(resource);
            
            }
            nodeResourceDetailsList.push(nodeResourceDetails);
        });
    });
    return endpoint === ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS
        ? {
            addResourceRelationshipsList: nodeResourceDetailsList
        }
        : {
            nodeResourceDetailsList : nodeResourceDetailsList
        }
}

/**
 * Generate Backend To Frontend Attribute Mapping for attributes from constants
 */
 export const reverseMapping = (obj) => {
    const newObj = {};
   Object.keys(obj).forEach(key => {
      if(newObj[obj[key]]){
         newObj[obj[key]] = key;
      }else{
         newObj[obj[key]] = key;
      }
   });
   return newObj;
 }

/**
 * Get endpoint for getResourceData
 */
export const fetchEndpointForGetResourceData = (resourceSearchCriteria) => {
    return [RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE,
        RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE].includes(resourceSearchCriteria.resourceType)
        ? ENDPOINT_GET_RESOURCES_RELATIONSHIPS
        : ENDPOINT_GET_RESOURCES_V2;
}

/**
 *
 * Fetch filterAttribute value based on filterType
 */
export const getFilterAttributeValue = (resourceSearchCriteria, isNARCSSDBinCreationEnabled) => {
    // Do not filter any bins if NARC SSD Bin creation is enabled unless a filterType is specified.
    if(!resourceSearchCriteria.filterType && isNARCSSDBinCreationEnabled) {
        return {};
    }

    switch (resourceSearchCriteria.filterType) {
        case TEMPERATURE_FILTER_TYPE:
            return {
                Temperature: [AMBIENT_TEMPERATURE_RATING, CHILLED_TEMPERATURE_RATING, FROZEN_TEMPERATURE_RATING]
            };
        default:
            return {
                REVIEW_STATUS: [REVIEW_STATUS_TYPES.REVIEWED, REVIEW_STATUS_TYPES.PENDING_REVIEW]
            };
    }
}

/**
 * 
 * Fetch request object for GetResourceData
 */
export const fetchRequestObjectForGetResourceData = (resourceSearchCriteria, pageToken, isNARCSSDBinCreationEnabled) => {
    let resourceType;

    switch (resourceSearchCriteria.resourceType) {
        case RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION:
            resourceType = RESOURCE_TYPES.CLUSTER;
            break;
        case RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION:
            resourceType = RESOURCE_TYPES.STAGING_ZONE;
            break;
        case RESOURCE_TYPES.AISLE_BIN_ASSOCIATION:
            resourceType = RESOURCE_TYPES.AISLE;
            break;
        case RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION:
            resourceType = RESOURCE_TYPES.LAUNCHPAD;
            break;
        case RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP:
        case RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP:
            resourceType = RESOURCE_TYPES.PICK_STATION;
            break;
        default:
            resourceType = resourceSearchCriteria.resourceType;
    }

    let requestObject = {
        nodeId : resourceSearchCriteria.nodeId,
        resourceType : resourceType,
        pageToken : pageToken
    };

    switch (resourceType) {
        case RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE:
        case RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE:
        case RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE:
            requestObject = {
                stationCode : resourceSearchCriteria.nodeId,
                resourceType : resourceType
            };
            break;
        case RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION:
            requestObject[GET_RESOURCES_V2_RESOURCE_TYPE_REQUEST_ATTRIBUTE] = RESOURCE_TYPES.CLUSTER;
            requestObject[GET_RESOURCES_V2_FILTER_REQUEST_ATTRIBUTE] = {Type : [CLUSTER_TYPES.TESSERACT]};
            break;
        case RESOURCE_TYPES.BIN:
            requestObject[GET_RESOURCES_V2_FILTER_REQUEST_ATTRIBUTE] = getFilterAttributeValue(resourceSearchCriteria, isNARCSSDBinCreationEnabled);
            break;
        default:
            break;
    }

    return requestObject;
}

/**
 * Fetch request object for DeprecateResourceAssociation
 */
 export const fetchRequestObjectForDeprecateResourceAssociation = (resource, resourceSearchCriteria, childResource) => {
     let resource_association = {};
    if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE){
        resource_association.resourceScannable = resource.Launchpad_Scannable
        resource_association.relatedResourceScannable = resource.Staging_Zone_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.DISTANCE
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE){
        resource_association.resourceScannable = resource.Pick_Station_Scannable
        resource_association.relatedResourceScannable = resource.Staging_Zone_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.DISTANCE
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE){
        resource_association.resourceScannable = resource.Aisle_Scannable
        resource_association.relatedResourceScannable = resource.Staging_Zone_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.DISTANCE
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION){
        resource_association.resourceScannable = resource.Staging_Zone_Scannable
        resource_association.relatedResourceScannable = childResource.Staging_Area_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION){
        resource_association.resourceScannable = resource.Cluster_Scannable
        resource_association.relatedResourceScannable = childResource.Aisle_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION){
        resource_association.resourceScannable = resource.Launchpad_Scannable
        resource_association.relatedResourceScannable = childResource.Staging_Zone_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.AISLE_BIN_ASSOCIATION){
        resource_association.resourceScannable = resource.Aisle_Scannable
        resource_association.relatedResourceScannable = childResource.Bin_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.DOCK_DOOR){
        resource_association.resourceScannable = resource.scannableId
        resource_association.relatedResourceScannable = childResource.Staging_Area_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION){
        resource_association.resourceScannable = resource.Cluster_Scannable
        resource_association.relatedResourceScannable = childResource.Bin_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP){
        resource_association.resourceScannable = resource.Pick_Station_Scannable
        resource_association.relatedResourceScannable = childResource.Cluster_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    } else if(resourceSearchCriteria.resourceType === RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP){
        resource_association.resourceScannable = resource.Pick_Station_Scannable
        resource_association.relatedResourceScannable = childResource.Staging_Area_Scannable
        resource_association.relationshipType = RELATIONSHIP_TYPES.PARENT_CHILD
    }
    return resource_association;
}

/**
 * Fetch request object for SubmitUpdatedResource
 */
 export const fetchRequestObjectForSubmitUpdatedResource = (resource, nodeId) => {
    let resource_association = {};
   if(resource.resourceType === RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE){
       resource_association.resourceLabel = resource.Launchpad_Label
       resource_association.relatedResourceLabel = resource.Staging_Zone_Label
       resource_association.relationshipType = RELATIONSHIP_TYPES.DISTANCE
   } else if(resource.resourceType === RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE){
       resource_association.resourceLabel = resource.Aisle_Label
       resource_association.relatedResourceLabel = resource.Staging_Zone_Label
       resource_association.relationshipType = RELATIONSHIP_TYPES.DISTANCE
   } else if(resource.resourceType === RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE){
       resource_association.resourceLabel = resource.Pick_Station_Label
       resource_association.relatedResourceLabel = resource.Staging_Zone_Label
       resource_association.relationshipType = RELATIONSHIP_TYPES.DISTANCE
   }
   resource_association.relationshipAttribute = {"Distance": resource.resourceAttributes["Distance"]};
   resource_association.nodeId = nodeId;
   return resource_association;
}

/**
 * parse request object for submitUpdateResource
 */
 export const parseRequestObjectForSubmitUpdatedResource = (resource, nodeId) => {
     if(!CAPABILITY_CONFIG.Resources.RESOURCE_TYPES.includes(resource.resourceType)){
        return resource;
     }
     let parsedResource = {};
    parsedResource.nodeId = nodeId;
    if(resource.resourceType === 'LAUNCHPAD'){
        (parsedResource.resourceAttributes) = {};
        (parsedResource.resourceAttributes)['NumStagingSpots'] = resource['Capacity_Of_Vehicles'];
    } else if(resource.resourceType === 'STAGING_ZONE'){
        (parsedResource.resourceAttributes) = {};
        (parsedResource.resourceAttributes)['Depth'] = resource['Cart_Capacity_of_Staging_Areas'];
    } else if(resource.resourceType === 'CLUSTER'){
        (parsedResource.resourceAttributes) = {};
        (parsedResource.resourceAttributes)['ColumnOrder'] = resource['Column_Order_of_Aisles'];
        (parsedResource.resourceAttributes)['DivertersCount'] = resource['No of Diverters'];
        (parsedResource.resourceAttributes)['Type'] = resource['Type'];
    } else if(resource.resourceType === 'FINGER'){
        (parsedResource.resourceAttributes) = {};
        (parsedResource.resourceAttributes)['ClusterPairs'] = resource['Cluster_Pairs'];
    }
    parsedResource.resourceType = resource.resourceType;
    parsedResource.isDeprecated = resource.isDeprecated;
    parsedResource.label = resource.label;
    parsedResource.scannableId = resource.scannableId;
    parsedResource.resourceId = resource.resourceId;
    return parsedResource;
}

/**
 * parse request object for submitUpdateResource
 */
 export const parseRequestObjectForUploadResources = (resource, nodeId, resourceType) => {
    let resource_association = {}
    resource_association.nodeId = nodeId;
    resource_association.relationshipType = RESOURCE_ASSOCIATION_DISTANCE_TYPES.includes(resourceType) 
        ? RELATIONSHIP_TYPES.DISTANCE
        : RELATIONSHIP_TYPES.PARENT_CHILD;
    if(resourceType === RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE){
        resource_association.resourceLabel = resource.Launchpad_Label
        resource_association.relatedResourceLabel = resource.Staging_Zone_Label
        resource_association.relationshipAttribute = {Distance: resource['Distance']}
    } else if(resourceType === RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE){
        resource_association.resourceLabel = resource.Pick_Station_Label
        resource_association.relatedResourceLabel = resource.Staging_Zone_Label
        resource_association.relationshipAttribute = {Distance: resource['Distance']}
    } else if(resourceType === RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE) {
        resource_association.resourceLabel = resource.Aisle_Label_Range
        resource_association.relatedResourceLabel = resource.Staging_Zone_Label
        resource_association.relationshipAttribute = {Distance: resource['Distance']}
    } else if(resourceType === 'STAGING_ZONE_STAGING_AREA_MAP'){
        resource_association.resourceLabel = resource.Staging_Zone_Label
        resource_association.relatedResourceLabel = resource.Staging_Area_Label
    } else if(resourceType === LAUNCHPAD_STAGING_ZONE_MAP){
        resource_association.resourceLabel = resource.Launchpad_Label
        resource_association.relatedResourceLabel = resource.Staging_Zone_Label
    } else if(resourceType === RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION){
        resource_association.resourceLabel = resource.Cluster_Label
        resource_association.relatedResourceLabel = resource.Aisle_Label
    } else if(resourceType === RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION){
        resource_association.resourceLabel = resource.Cluster_Label
        resource_association.relatedResourceLabel = resource.Bin_Label
    } else if(resourceType === RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP){
        resource_association.resourceLabel = resource.Pick_Station_Label
        resource_association.relatedResourceLabel = resource.Cluster_Label
    } else if(resourceType === RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP){
        resource_association.resourceLabel = resource.Pick_Station_Label
        resource_association.relatedResourceLabel = resource.Staging_Area_Label
    } else if(resourceType === RESOURCE_TYPES.DOCK_DOOR_STAGING_AREA_MAP){
        resource_association.resourceLabel = resource.Dock_Door_Label;
        resource_association.relatedResourceLabel = resource.Staging_Area_Label
    }
    return resource_association;
}

export const getEndpointBasedOnResourceType = (narc_sls_intergration_enabled, isSSDMMOTEnabled, resourceDataMap) => {
    let endpoint = ENDPOINT_BULK_CREATE_RESOURCES;
    if(narc_sls_intergration_enabled || isSSDMMOTEnabled){
        if( resourceDataMap && Object.keys(resourceDataMap).length > 0 && (VALID_SHEET_NAMES.RESOURCE_ASSOCIATIONS_EXCEL).includes(Object.keys(resourceDataMap)[0])){
            endpoint = ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS;
        }
    }
    return endpoint;
}

export const getuploadTypeBasedOnResourceType = (narc_sls_intergration_enabled, isSSDMMOTEnabled, resourceDataMap) => {
    let uploadType = TYPE.RESOURCE;
    if(narc_sls_intergration_enabled || isSSDMMOTEnabled){
        if( resourceDataMap && Object.keys(resourceDataMap).length > 0 && (VALID_SHEET_NAMES.RESOURCE_ASSOCIATIONS_EXCEL).includes(Object.keys(resourceDataMap)[0])){
            uploadType = TYPE.ASSOCIATION;
        }
    }
    return uploadType;
}

/**
 * Checks if an attributes are valid while updating resource modal
 */
export const checkIsValidEditModal = (fieldName, fieldValue) => {
    if(fieldName === ALL_ATTRIBUTES.Capacity_Of_Vehicles 
        || fieldName === ALL_ATTRIBUTES.Cart_Capacity_of_Staging_Areas
        || fieldName === ALL_ATTRIBUTES.DISTANCE
        || fieldName === ALL_ATTRIBUTES.DivertersCount
        || fieldName === ALL_ATTRIBUTES.Type
        || fieldName === ALL_ATTRIBUTES.Capacity){
            if(isNaN(fieldValue))
                return {isValidEditModal: !isNaN(fieldValue), isValidEditModalMessage: fieldName+" should be a number"};
    }
    return {isValidEditModal: true};
}

/**
 * Validates the headers while user uploads csv file
 */
export const validateHeaderRows = (headerRows, sheetName) => {
    if(!Object.keys(VALID_HEADER_ROWS).includes(sheetName)){
        return null;
    }
    let uploadBulkResourceError = null;
    headerRows.forEach(row => {
        if(!VALID_HEADER_ROWS[sheetName].includes(row)){
            uploadBulkResourceError = "Invalid Header Rows";
            return;
        }
    });
    if (uploadBulkResourceError) {
        return uploadBulkResourceError;
    }
    if (Object.keys(REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL).includes(sheetName)) {
        const requiredHeadersNotPresentinExcelFile = fetchRequiredHeadersNotPresentInFile(headerRows, REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL[sheetName])
        if (requiredHeadersNotPresentinExcelFile && requiredHeadersNotPresentinExcelFile.length > 0) {
            uploadBulkResourceError = `Required header(s) ${requiredHeadersNotPresentinExcelFile.toString()} not present in ${sheetName} sheet`;
            return uploadBulkResourceError;
        }
    }
    return null;
}

/**
 * 
 * @param {*} deprecationResource : Resource to be deprecated
 * @param {*} resourceSearchCriteria : Resource Search Criteria
 * @returns Alert message when user clicks on deprecate button
 */
export const getDeprecationAlertMessage = (deprecationResource, resourceSearchCriteria, childResource) => {
    if ([RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE,
            RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE].includes(resourceSearchCriteria.resourceType)) {
        return <FormattedMessage id="node_resource.confirm_deprecate_resources_alert_association"
                    defaultMessage="Are you sure you want to deprecate {resourceLabel} - {relatedResourceLabel} Association?"
                    values={{resourceLabel: deprecationResource[DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES[resourceSearchCriteria.resourceType][0]],
                            relatedResourceLabel: deprecationResource[DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES[resourceSearchCriteria.resourceType][1]]}}
                />
    }
   if (DEPRECATE_HIERARCHY_RESOURCE_ASSOCIATION_TYPES.includes(resourceSearchCriteria.resourceType)) {
    return <FormattedMessage id="node_resource.confirm_deprecate_resources_alert_association"
                defaultMessage="Are you sure you want to deprecate {resourceLabel} - {relatedResourceLabel} Association?"
                values={{resourceLabel: deprecationResource[DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES[resourceSearchCriteria.resourceType][0]],
                        relatedResourceLabel: childResource[DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES[resourceSearchCriteria.resourceType][1]]}}
            />
    }
    if (resourceSearchCriteria.resourceType === RESOURCE_TYPES.AISLE) {
        return <FormattedMessage id="node_resource.confirm_deprecate_resources_alert_aisle"
                    defaultMessage={DEFAULT_FORMATTED_MESSAGES.confirm_deprecate_resources_alert_aisle}
                    values={{resourceLabel: deprecationResource.label}}
                />
    }
    if (CAPABILITY_CONFIG.Resources.RESOURCE_TYPES.includes(resourceSearchCriteria.resourceType)) {
        return <FormattedMessage id="node_resource.confirm_deprecate_resources_alert_force_flag_false"
                    defaultMessage={DEFAULT_FORMATTED_MESSAGES.confirm_deprecate_resources_alert_force_flag_false}
                    values={{resourceLabel: deprecationResource.label}}
                />
    }
    return <FormattedMessage id="node_resource.confirm_deprecate_resources_alert"
	                 defaultMessage={DEFAULT_FORMATTED_MESSAGES.confirm_deprecate_resources_alert_generic}
	                 values={{resourceLabel: deprecationResource.label}}
	         />
}

/**
     * Renders Header Message for Create/Upate button
     * @param narc_sls_intergration_enabled
     * @returns {JSX.Element}
     */
export const renderHeaderMessage = (narc_sls_intergration_enabled) => {
    return narc_sls_intergration_enabled
    ?   <FormattedMessage id="node_resources.manage_resources" defaultMessage={DEFAULT_FORMATTED_MESSAGES.manage_resources}/>
    :   <FormattedMessage id="node_resources.create_resource" defaultMessage={DEFAULT_FORMATTED_MESSAGES.create_resource}/>
}